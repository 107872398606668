var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex",
          staticStyle: { position: "absolute", right: "0px", top: "-47px" },
        },
        [
          _vm.customer && _vm.customer.customer
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", right: "", color: "error" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _vm.isGranted("CUSTOMERS_LOCK")
                              ? _c(
                                  "div",
                                  _vm._g(
                                    _vm._b(
                                      { staticClass: "makeObsolete mr-1" },
                                      "div",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    !_vm.customer.customer.obsolete
                                      ? [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { "x-small": "" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.lockCust()
                                                },
                                              },
                                            },
                                            [_vm._v("fa-moon")]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-small": "",
                                                color: "warning",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.lockCust()
                                                },
                                              },
                                            },
                                            [_vm._v("fa-sun")]
                                          ),
                                        ],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1801149219
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.customer.customer.obsolete
                          ? "Activer"
                          : "Mettre en sommeil"
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.customer &&
          _vm.customer.customer &&
          _vm.isGranted("CUSTOMERS_ANONYME")
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", right: "", color: "error" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "makeAnonyme mr-1 cursor-pointer",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.toggleAnonymize(
                                          _vm.customer.customer
                                        )
                                      },
                                    },
                                  },
                                  "div",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                !_vm.customer.customer.anonyme
                                  ? [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "x-small": "",
                                            color: "red",
                                          },
                                        },
                                        [_vm._v("fa-mask")]
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "v-icon",
                                        { attrs: { "x-small": "" } },
                                        [_vm._v("fa-eye")]
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3156179353
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.customer.customer.anonyme
                          ? "Retirer l'anonymat"
                          : "Anonymiser"
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.customer && _vm.customer.customer
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "open-on-hover": "",
                    "close-on-content-click": false,
                    "offset-y": "",
                    "z-index": "1000",
                    "max-width": "350",
                    "min-width": "350",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  { attrs: { "x-small": "" } },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("fa-info-circle")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2009903048
                  ),
                },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "0" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "py-2 grey--text text--darken-3 text-uppercase subtitle-1",
                        },
                        [_vm._v(" Historique éditions ")]
                      ),
                      _c("v-divider"),
                      _c("v-card-text", [
                        _c("span", [
                          _vm.customer.entreprise.createdAt
                            ? _c("div", { staticClass: "my-1" }, [
                                _vm.customer.entreprise.createdUser
                                  ? _c(
                                      "span",
                                      [
                                        _c("UserAvatar", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            size: "35",
                                            userId:
                                              _vm.customer.entreprise
                                                .createdUser.id,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { size: "35" },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/images/users/nopic.png",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                _vm._v(
                                  " Création le " +
                                    _vm._s(
                                      _vm
                                        .dayjs(
                                          _vm.customer.entreprise.createdAt
                                        )
                                        .format("DD/MM/YYYY à HH:mm")
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                          _vm.customer.entreprise.updatedAt
                            ? _c("div", [
                                _vm.customer.entreprise.updatedUser
                                  ? _c(
                                      "span",
                                      [
                                        _c("UserAvatar", {
                                          staticClass: "mr-2",
                                          attrs: {
                                            size: "35",
                                            userId:
                                              _vm.customer.entreprise
                                                .updatedUser.id,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "span",
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { size: "35" },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/images/users/nopic.png",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                _vm._v(
                                  " Dernière modification le " +
                                    _vm._s(
                                      _vm
                                        .dayjs(
                                          _vm.customer.entreprise.updatedAt
                                        )
                                        .format("DD/MM/YYYY à HH:mm")
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "MaterialCard",
        {
          staticClass: "px-5 py-3",
          attrs: { color: "primary" },
          scopedSlots: _vm._u([
            {
              key: "heading",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-tabs",
                                {
                                  attrs: {
                                    "background-color": "transparent",
                                    "slider-color": "white",
                                    grow: "",
                                    vertical: _vm.isMobile(),
                                  },
                                  model: {
                                    value: _vm.tabs,
                                    callback: function ($$v) {
                                      _vm.tabs = $$v
                                    },
                                    expression: "tabs",
                                  },
                                },
                                [
                                  _c(
                                    "v-tab",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.tabs === 0
                                            ? (_vm.toggleInfo = !_vm.toggleInfo)
                                            : (_vm.tabs = 0)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "pa-0 ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _vm.customer &&
                                              _vm.customer.customer
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "1.1rem",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.customer
                                                            .entreprise.name
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.toggleInfo = false
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "pa-0 ma-0" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "px-0",
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { small: "" },
                                                },
                                                [_vm._v(" fa-retweet ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "10" } },
                                            [
                                              _c(
                                                "v-badge",
                                                {
                                                  attrs: {
                                                    content:
                                                      _vm.maxItemsSubs !== 0
                                                        ? _vm.maxItemsSubs
                                                        : "0",
                                                    color: "secondary",
                                                    overlap: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "mr-6" },
                                                    [_vm._v("Abonnements")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.infras, function (parc, key) {
                                    return _c(
                                      "v-tab",
                                      {
                                        key: key,
                                        on: {
                                          click: function ($event) {
                                            _vm.toggleInfo = false
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "pa-0 ma-0" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "px-0",
                                                attrs: { cols: "1" },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: { small: "" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(parc.icon) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "11" } },
                                              [
                                                _c(
                                                  "v-badge",
                                                  {
                                                    attrs: {
                                                      content:
                                                        _vm.items[key] &&
                                                        _vm.items[key].length
                                                          ? _vm.items[key]
                                                              .length
                                                          : "0",
                                                      color: "secondary",
                                                      overlap: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "mr-6" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(parc.name)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "modal",
            {
              attrs: { "max-width": "1024" },
              scopedSlots: _vm._u(
                [
                  _vm.addHeader
                    ? {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "strong",
                              { staticClass: "text-h5 mb-0 text-uppercase" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.addHeader && _vm.addHeader.id
                                        ? "Edition"
                                        : "Ajout"
                                    ) +
                                    " d'un élément de parc"
                                ),
                              ]
                            ),
                            _c("br"),
                            _c("small", { staticClass: "mt-0" }, [void 0], 2),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  {
                    key: "actions",
                    fn: function () {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "rounded-1 px-3",
                            attrs: {
                              variant: "flat",
                              color: "primary",
                              elevation: "0",
                              depressed: "",
                              small: "",
                            },
                            on: { click: _vm.saveParcElem },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-2", attrs: { "x-small": "" } },
                              [_vm._v("fas fa-check fa-fw")]
                            ),
                            _vm._v(" Enregistrer "),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-4",
                            attrs: { color: "secondary", text: "" },
                            on: {
                              click: function ($event) {
                                _vm.addParcModal = false
                              },
                            },
                          },
                          [_vm._v(" Fermer ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.addParcModal,
                callback: function ($$v) {
                  _vm.addParcModal = $$v
                },
                expression: "addParcModal",
              },
            },
            [
              _vm.addHeader
                ? _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }),
                      _vm._l(
                        Object.keys(_vm.addHeader.classic),
                        function (field, key) {
                          return _c(
                            "v-col",
                            {
                              key: key,
                              staticClass: "py-0",
                              attrs: { cols: "6" },
                            },
                            [
                              field === "date"
                                ? [
                                    _c(
                                      "v-menu",
                                      {
                                        ref: "menu1",
                                        refInFor: true,
                                        attrs: {
                                          "close-on-content-click": false,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "max-width": "290px",
                                          "min-width": "auto",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            label:
                                                              _vm.addHeader
                                                                .classic[field]
                                                                .label,
                                                          },
                                                          on: {
                                                            blur: function (
                                                              $event
                                                            ) {
                                                              _vm.date =
                                                                _vm.parseDate(
                                                                  _vm.dateFormatted
                                                                )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.dateFormatted,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.dateFormatted =
                                                                $$v
                                                            },
                                                            expression:
                                                              "dateFormatted",
                                                          },
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.menuDate,
                                          callback: function ($$v) {
                                            _vm.menuDate = $$v
                                          },
                                          expression: "menuDate",
                                        },
                                      },
                                      [
                                        _c("v-date-picker", {
                                          attrs: { "no-title": "" },
                                          on: {
                                            input: function ($event) {
                                              _vm.menuDate = false
                                            },
                                          },
                                          model: {
                                            value: _vm.date,
                                            callback: function ($$v) {
                                              _vm.date = $$v
                                            },
                                            expression: "date",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : field === "birthday"
                                ? [
                                    _c(
                                      "v-menu",
                                      {
                                        ref: "menu1",
                                        refInFor: true,
                                        attrs: {
                                          "close-on-content-click": false,
                                          transition: "scale-transition",
                                          "offset-y": "",
                                          "max-width": "290px",
                                          "min-width": "auto",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            outlined: "",
                                                            label:
                                                              _vm.addHeader
                                                                .classic[field]
                                                                .label,
                                                          },
                                                          on: {
                                                            blur: function (
                                                              $event
                                                            ) {
                                                              _vm.dateBirthday =
                                                                _vm.parseDateWithoutYear(
                                                                  _vm.dateBirthdayFormatted
                                                                )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.dateBirthdayFormatted,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.dateBirthdayFormatted =
                                                                $$v
                                                            },
                                                            expression:
                                                              "dateBirthdayFormatted",
                                                          },
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.menuBirthdayDate,
                                          callback: function ($$v) {
                                            _vm.menuBirthdayDate = $$v
                                          },
                                          expression: "menuBirthdayDate",
                                        },
                                      },
                                      [
                                        _c("v-date-picker", {
                                          attrs: { "no-title": "" },
                                          on: {
                                            input: function ($event) {
                                              _vm.menuBirthdayDate = false
                                            },
                                          },
                                          model: {
                                            value: _vm.dateBirthday,
                                            callback: function ($$v) {
                                              _vm.dateBirthday = $$v
                                            },
                                            expression: "dateBirthday",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : field === "body"
                                ? [
                                    _c("v-textarea", {
                                      attrs: {
                                        outlined: "",
                                        label:
                                          _vm.addHeader.classic[field].label,
                                      },
                                      model: {
                                        value:
                                          _vm.addHeader.classic[field].value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addHeader.classic[field],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addHeader.classic[field].value",
                                      },
                                    }),
                                  ]
                                : [
                                    _c("v-text-field", {
                                      attrs: {
                                        outlined: "",
                                        label:
                                          _vm.addHeader.classic[field].label,
                                      },
                                      model: {
                                        value:
                                          _vm.addHeader.classic[field].value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addHeader.classic[field],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addHeader.classic[field].value",
                                      },
                                    }),
                                  ],
                            ],
                            2
                          )
                        }
                      ),
                      _vm._l(
                        Object.keys(_vm.addHeader.spe),
                        function (field, key) {
                          return _c(
                            "v-col",
                            {
                              key: key + "-spe",
                              staticClass: "py-0",
                              attrs: { cols: "6" },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  outlined: "",
                                  clearable: "",
                                  "menu-props": { maxHeight: "300" },
                                  "item-value": "id",
                                  "item-text": "name",
                                  label: _vm.addHeader.spe[field].label,
                                  items: _vm.addHeader.spe[field].items,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleClearField(`spe`, field)
                                  },
                                },
                                model: {
                                  value: _vm.addHeader.spe[field].value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addHeader.spe[field],
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "addHeader.spe[field].value",
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "6" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "menu-props": { maxHeight: "300" },
                              items: _vm.concurrentsList,
                              loading: _vm.isLoading,
                              clearable: "",
                              "search-input": _vm.search.name,
                              "item-text": "entreprise.name",
                              "item-value": "entreprise.concurrents.id",
                              label: "Rechercher un prestataire",
                              outlined: "",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                return _vm.$set(_vm.search, "name", $event)
                              },
                              "update:search-input": function ($event) {
                                return _vm.$set(_vm.search, "name", $event)
                              },
                              change: function ($event) {
                                return _vm.handleClearField("concurrent")
                              },
                            },
                            model: {
                              value: _vm.addHeader.concurrent,
                              callback: function ($$v) {
                                _vm.$set(_vm.addHeader, "concurrent", $$v)
                              },
                              expression: "addHeader.concurrent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "modal",
            {
              attrs: { "max-width": "1024" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "strong",
                        { staticClass: "text-h5 mb-0 text-uppercase" },
                        [_vm._v("Edition client")]
                      ),
                      _c("br"),
                      _c("small", { staticClass: "mt-0" }, [
                        _vm._v("Commencez par saisir le N° de SIRET"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "rounded-1 px-3",
                          attrs: {
                            disabled:
                              _vm.alreadySiret !== null &&
                              _vm.alreadySiret.id ===
                                _vm.newClient.entreprise.siret
                                ? true
                                : false,
                            variant: "flat",
                            color: "primary",
                            elevation: "0",
                            depressed: "",
                            small: "",
                          },
                          on: { click: _vm.saveClient },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { "x-small": "" } },
                            [_vm._v("fas fa-check fa-fw")]
                          ),
                          _vm._v(" Enregistrer "),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "secondary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.openEditModal = false
                            },
                          },
                        },
                        [_vm._v(" Fermer ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.openEditModal,
                callback: function ($$v) {
                  _vm.openEditModal = $$v
                },
                expression: "openEditModal",
              },
            },
            [
              _vm.alreadySiret &&
              _vm.alreadySiret.id !== _vm.newClient.entreprise.id
                ? _c("v-alert", { attrs: { dense: "", type: "error" } }, [
                    _c("div", [_vm._v("Un compte existe déjà avec ce SIRET:")]),
                    _c("div", [_vm._v(_vm._s(_vm.alreadySiret.name))]),
                    _c(
                      "div",
                      [
                        _vm.isGranted("CUSTOMERS_VIEW")
                          ? _c(
                              "v-btn",
                              {
                                attrs: { color: "secondary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push({
                                      name: "Client",
                                      params: { id: _vm.alreadySiret.id },
                                    })
                                  },
                                },
                              },
                              [_vm._v("Accéder au compte ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.newClient
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          this.$store.state.settings.sage
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 py-1 ma-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("v-text-field", {
                                    staticClass: "pa-0 ma-0",
                                    attrs: {
                                      maxlength: 17,
                                      "persistent-hint": "",
                                      hint: "Voir la norme de nommage",
                                      label: "N° Client",
                                      outlined: "",
                                      readonly:
                                        _vm.originalCustomer.customer
                                          .ctNumAb !== null &&
                                        _vm.originalCustomer.customer
                                          .ctNumAb !== "",
                                      disabled:
                                        _vm.originalCustomer.customer
                                          .ctNumAb !== null &&
                                        _vm.originalCustomer.customer
                                          .ctNumAb !== "",
                                    },
                                    model: {
                                      value: _vm.newClient.customer.ctNumAb,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.newClient.customer,
                                          "ctNumAb",
                                          $$v
                                        )
                                      },
                                      expression: "newClient.customer.ctNumAb",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  maxlength: "35",
                                  "hide-details": "true",
                                  outlined: "",
                                  label: "Intitulé",
                                },
                                model: {
                                  value: _vm.newClient.entreprise.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise,
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression: "newClient.entreprise.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  maxlength: "14",
                                  outlined: "",
                                  label: "SIRET",
                                  "hide-details": "true",
                                },
                                model: {
                                  value: _vm.newClient.entreprise.siret,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise,
                                      "siret",
                                      $$v
                                    )
                                  },
                                  expression: "newClient.entreprise.siret",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  "hide-details": "true",
                                  maxlength: 35,
                                  outlined: "",
                                  label: "Adresse",
                                },
                                model: {
                                  value:
                                    _vm.newClient.entreprise.adresse.address,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise.adresse,
                                      "address",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "newClient.entreprise.adresse.address",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  "hide-details": "true",
                                  maxlength: 35,
                                  outlined: "",
                                  label: "Complément",
                                },
                                model: {
                                  value:
                                    _vm.newClient.entreprise.adresse.address2,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise.adresse,
                                      "address2",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "newClient.entreprise.adresse.address2",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  "hide-details": "true",
                                  maxlength: 5,
                                  outlined: "",
                                  label: "Code postal",
                                },
                                model: {
                                  value:
                                    _vm.newClient.entreprise.adresse.postalCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise.adresse,
                                      "postalCode",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "newClient.entreprise.adresse.postalCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-1",
                                attrs: {
                                  "hide-details": "true",
                                  maxlength: 35,
                                  outlined: "",
                                  label: "Ville",
                                },
                                model: {
                                  value: _vm.newClient.entreprise.adresse.city,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise.adresse,
                                      "city",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "newClient.entreprise.adresse.city",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-switch", {
                                attrs: { label: "Client Indirect" },
                                model: {
                                  value: _vm.newClient.customer.isIndirect,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.customer,
                                      "isIndirect",
                                      $$v
                                    )
                                  },
                                  expression: "newClient.customer.isIndirect",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-0",
                                attrs: {
                                  "persistent-hint": "",
                                  type: "email",
                                  error:
                                    _vm.newClient &&
                                    _vm.newClient.entreprise.email &&
                                    _vm.newClient.entreprise.email.length === 0,
                                  hint: "Obligatoire pour la synchronisation avec SAGE",
                                  outlined: "",
                                  label: "Email",
                                },
                                model: {
                                  value: _vm.newClient.entreprise.email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise,
                                      "email",
                                      $$v
                                    )
                                  },
                                  expression: "newClient.entreprise.email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-select", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  "menu-props": { maxHeight: "300" },
                                  "hide-details": "true",
                                  outlined: "",
                                  label: "Effectifs",
                                  "item-value": "id",
                                  "item-text": "name",
                                  items: _vm.listPeople,
                                },
                                model: {
                                  value: _vm.newClient.entreprise.peopleId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise,
                                      "peopleId",
                                      $$v
                                    )
                                  },
                                  expression: "newClient.entreprise.peopleId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  "hide-details": "true",
                                  outlined: "",
                                  label: "APE",
                                },
                                model: {
                                  value: _vm.newClient.entreprise.ape,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise,
                                      "ape",
                                      $$v
                                    )
                                  },
                                  expression: "newClient.entreprise.ape",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  "hide-details": "true",
                                  maxlength: 21,
                                  outlined: "",
                                  label: "Téléphone",
                                },
                                model: {
                                  value: _vm.newClient.entreprise.phone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise,
                                      "phone",
                                      $$v
                                    )
                                  },
                                  expression: "newClient.entreprise.phone",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  "hide-details": "true",
                                  maxlength: 21,
                                  outlined: "",
                                  label: "FAX",
                                },
                                model: {
                                  value: _vm.newClient.entreprise.phone2,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise,
                                      "phone2",
                                      $$v
                                    )
                                  },
                                  expression: "newClient.entreprise.phone2",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "pa-0 ma-0 mb-3",
                                attrs: {
                                  "hide-details": "true",
                                  outlined: "",
                                  label: "Site Web",
                                  type: "url",
                                },
                                model: {
                                  value: _vm.newClient.entreprise.site,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.newClient.entreprise,
                                      "site",
                                      $$v
                                    )
                                  },
                                  expression: "newClient.entreprise.site",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0 py-1 ma-0",
                              attrs: { cols: "12" },
                            },
                            [
                              this.$store.state.settings.sage
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "text-left pt-3",
                                      attrs: {
                                        dense: "",
                                        border: "top",
                                        "colored-border": "",
                                        elevation: "2",
                                        "x-small": "",
                                        type: "info",
                                        color: "primary",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "caption" }, [
                                        _c("h3", { staticClass: "text-h6" }, [
                                          _vm._v("Norme N° Client"),
                                        ]),
                                        _c("ul", [
                                          _c("li", [
                                            _vm._v(
                                              "NOM de l’entreprise sans SARL, SA, EURL, GROUPE…"
                                            ),
                                          ]),
                                          _c("li", [
                                            _vm._v("M + VILLE si Mairie"),
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "CC + Ville si Communauté de Communes"
                                            ),
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "A défaut on appelle le service COMPTA/ADV"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              staticClass: "transparent",
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-expand-transition",
                            [
                              _c(
                                "v-container",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: true,
                                      expression: "true",
                                    },
                                  ],
                                  staticClass: "mb-2",
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: {
                                            cols: "12",
                                            lg: "4",
                                            xl: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "py-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "float-md-right",
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        color: "warning",
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-1",
                                                                      attrs: {
                                                                        fab: "",
                                                                        "x-small":
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        color:
                                                                          "warning",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openLink(
                                                                              1
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " fa-fw fa-address-book"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Pages jaunes"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        color: "error",
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-1",
                                                                      attrs: {
                                                                        fab: "",
                                                                        "x-small":
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        color:
                                                                          "error",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openLink(
                                                                              2
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fab fa-fw fa-google"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Google"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        color: "primary",
                                                      },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-1",
                                                                      attrs: {
                                                                        fab: "",
                                                                        "x-small":
                                                                          "",
                                                                        outlined:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openLink(
                                                                              3
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " fa-fw fa-building"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Société.com"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "ml-4",
                                                                      attrs: {
                                                                        fab: "",
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.editCustomerOpen,
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fa-fw fa-pen"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ]),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Editer le client"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: { small: "" },
                                                },
                                                [
                                                  _vm._v(
                                                    "fa-fw fa-map-marker-alt"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-italic grey--text text-body-2 text--darken-2",
                                                },
                                                [
                                                  _vm.customer.entreprise
                                                    .adresse.address
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.customer
                                                              .entreprise
                                                              .adresse.address
                                                          )
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  _vm.customer.entreprise
                                                    .adresse.address2
                                                    ? [
                                                        _vm._v(
                                                          " - " +
                                                            _vm._s(
                                                              _vm.customer
                                                                .entreprise
                                                                .adresse
                                                                .address2
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.customer.entreprise
                                                          .adresse.postalCode +
                                                          " " +
                                                          _vm.customer
                                                            .entreprise.adresse
                                                            .city
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                2
                                              ),
                                              _c("br", {
                                                staticStyle: { clear: "both" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.customer.entreprise.phone
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("fa-fw fa-phone")]
                                                  ),
                                                  _c("span", [
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          href:
                                                            "tel:" +
                                                            _vm.customer
                                                              .entreprise.phone,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.customer
                                                              .entreprise.phone
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        lg: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fa-fw fa-envelope"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.customer.entreprise
                                                        .email
                                                        ? [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "grey--text text--darken-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      href:
                                                                        "mailto:" +
                                                                        _vm
                                                                          .customer
                                                                          .entreprise
                                                                          .email,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .customer
                                                                          .entreprise
                                                                          .email
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  ),
                                                  _vm.customer.entreprise.site
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            xl: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-fw fa-link"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("span", [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: _vm
                                                                    .customer
                                                                    .entreprise
                                                                    .site,
                                                                  target:
                                                                    "_blank",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.customer
                                                                      .entreprise
                                                                      .site
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            xl: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-fw fa-link"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("span", [
                                                            _vm._v(" - "),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                  _vm.customer.customer.ctNumAb
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            xl: "6",
                                                          },
                                                          on: {
                                                            dblclick: function (
                                                              $event
                                                            ) {
                                                              return _vm.copy(
                                                                _vm.customer
                                                                  .customer
                                                                  .ctNumAb
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2 green--text text--lighten-1",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "far fa-fw fa-id-badge"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.customer
                                                                      .customer
                                                                      .ctNumAb
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    "x-small":
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.copy(
                                                                          _vm
                                                                            .customer
                                                                            .customer
                                                                            .ctNumAb
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "far fa-copy fa-fw"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _vm.customer.entreprise.siret
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            xl: "6",
                                                          },
                                                          on: {
                                                            dblclick: function (
                                                              $event
                                                            ) {
                                                              return _vm.copy(
                                                                _vm.customer
                                                                  .entreprise
                                                                  .siret
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-fw fa-barcode"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.customer
                                                                    .entreprise
                                                                    .siret
                                                                ) + " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-small": "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.copy(
                                                                      _vm
                                                                        .customer
                                                                        .entreprise
                                                                        .siret
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "far fa-copy fa-fw"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            xl: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-fw fa-barcode"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-2",
                                                            },
                                                            [_vm._v(" - ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                  _vm.customer.entreprise
                                                    .entrepriseSieges
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            xl: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-fw fa-building"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      depressed:
                                                                                        "",
                                                                                      outlined:
                                                                                        "",
                                                                                      rounded:
                                                                                        "",
                                                                                      color:
                                                                                        "indigo",
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.$router.push(
                                                                                          {
                                                                                            name: "Client",
                                                                                            params:
                                                                                              {
                                                                                                id: _vm
                                                                                                  .customer
                                                                                                  .entreprise
                                                                                                  .entrepriseSieges
                                                                                                  .id,
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                "v-btn",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .customer
                                                                                    ?.entreprise
                                                                                    ?.entrepriseSieges
                                                                                    ?.name
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  2750832337
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Siège social"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm.customer?.entreprise
                                                        ?.entreprisesSecondaire &&
                                                      _vm.customer?.entreprise
                                                        ?.entreprisesSecondaire
                                                        .length > 0
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            xl: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-fw fa-building"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.customer
                                                              ?.entreprise
                                                              ?.entreprisesSecondaire,
                                                            function (
                                                              secondaire,
                                                              i
                                                            ) {
                                                              return _c(
                                                                "v-tooltip",
                                                                {
                                                                  key:
                                                                    "entreprise-secondaire-" +
                                                                    i,
                                                                  attrs: {
                                                                    top: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                            attrs,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1",
                                                                                      attrs:
                                                                                        {
                                                                                          depressed:
                                                                                            "",
                                                                                          outlined:
                                                                                            "",
                                                                                          rounded:
                                                                                            "",
                                                                                          color:
                                                                                            "green",
                                                                                          "x-small":
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$router.push(
                                                                                              {
                                                                                                name: "Client",
                                                                                                params:
                                                                                                  {
                                                                                                    id: secondaire.id,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      secondaire.name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Etablissement secondaire"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    : _vm.customer?.entreprise
                                                        ?.siege
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            xl: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-fw fa-building"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "font-italic",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Siège (entreprises secondaires inconnues)"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _vm.customer.entreprise.ape
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-fw fa-university"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.customer
                                                                      .entreprise
                                                                      .ape
                                                                  )
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "font-italic text-caption ma-0 pa-0 ml-3 pl-4",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getApeLabel()
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.customer.people
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: {
                                                            cols: "12",
                                                            lg: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fa-fw fa-users"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text text--darken-2",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.customer
                                                                      .people
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            lg: "8",
                                            xl: "8",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "8",
                                                    lg: "6",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          staticClass: "mb-1",
                                                          attrs: {
                                                            align: "center",
                                                          },
                                                        },
                                                        [
                                                          _c("v-divider"),
                                                          _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                    text: "",
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.isGranted(
                                                                          "CUSTOMERS_EDITAFFECTATIONS"
                                                                        )
                                                                          ? (_vm.modalAffect = true)
                                                                          : ""
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Affectations "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-divider"),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "py-0 mt-0 text-center",
                                                      attrs: { cols: "10" },
                                                    },
                                                    _vm._l(
                                                      _vm.infrasAll,
                                                      function (infra, key) {
                                                        return _c(
                                                          "v-tooltip",
                                                          {
                                                            key: key,
                                                            attrs: {
                                                              bottom: "",
                                                              color:
                                                                infra.color,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1 mb-1 text-end toggleKeyCustomer",
                                                                              attrs:
                                                                                {
                                                                                  width:
                                                                                    "75",
                                                                                  height:
                                                                                    "30",
                                                                                  justify:
                                                                                    "end",
                                                                                  depressed:
                                                                                    "",
                                                                                  rounded:
                                                                                    "",
                                                                                  color:
                                                                                    infra.color,
                                                                                  outlined:
                                                                                    _vm.customer.custoKey.includes(
                                                                                      infra.id
                                                                                    )
                                                                                      ? false
                                                                                      : true,
                                                                                  "x-small":
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.setAffects(
                                                                                      infra.id
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-btn",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm
                                                                            .customer
                                                                            .users[
                                                                            infra
                                                                              .id
                                                                          ]
                                                                            ? _c(
                                                                                "v-avatar",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      position:
                                                                                        "absolute",
                                                                                      left: "-9.5px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      left: "",
                                                                                      size: "30",
                                                                                      color:
                                                                                        infra.color,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "UserAvatar",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          size: "30",
                                                                                          userId:
                                                                                            _vm
                                                                                              .customer
                                                                                              .users[
                                                                                              infra
                                                                                                .id
                                                                                            ]
                                                                                              .id,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "ml-4",
                                                                              attrs:
                                                                                {
                                                                                  "x-small":
                                                                                    "",
                                                                                  color:
                                                                                    _vm.customer.custoKey.includes(
                                                                                      infra.id
                                                                                    )
                                                                                      ? ""
                                                                                      : "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    infra.icon
                                                                                      ? infra.icon
                                                                                      : "fa-star"
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm.customer.custoKey.includes(
                                                                            infra.id
                                                                          ) &&
                                                                          false
                                                                            ? _c(
                                                                                "v-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-4",
                                                                                  attrs:
                                                                                    {
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fa-key "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-center grey--text text--darken-4",
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      infra.name
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("br"),
                                                                _vm.customer
                                                                  .users[
                                                                  infra.id
                                                                ]
                                                                  ? [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-capitalize",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.customer.users[
                                                                                infra
                                                                                  .id
                                                                              ].firstname.toLowerCase()
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .customer
                                                                              .users[
                                                                              infra
                                                                                .id
                                                                            ]
                                                                              .lastname
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  : [
                                                                      _vm._v(
                                                                        " Aucune affectation "
                                                                      ),
                                                                    ],
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass:
                                                        "py-2 mt-9-px",
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "py-1 text-center",
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-container",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    align:
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              text: "",
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.isGranted(
                                                                                  "CUSTOMERS_EDITGROUPEMENTS"
                                                                                )
                                                                                  ? _vm.openGroups()
                                                                                  : ""
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Groupes "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._l(
                                                            _vm.customer.groups,
                                                            function (
                                                              group,
                                                              key
                                                            ) {
                                                              return _c(
                                                                "v-btn",
                                                                {
                                                                  key: key,
                                                                  staticClass:
                                                                    "mr-1 mb-1",
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    rounded: "",
                                                                    outlined:
                                                                      "",
                                                                    color:
                                                                      "primary",
                                                                    "x-small":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .groups[
                                                                          parseInt(
                                                                            group
                                                                          )
                                                                        ]
                                                                          ? _vm
                                                                              .groups[
                                                                              parseInt(
                                                                                group
                                                                              )
                                                                            ]
                                                                              .name
                                                                          : ""
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                      _vm.groups
                                                        ? _c("v-col", {
                                                            staticClass: "py-1",
                                                            attrs: {
                                                              cols: "10",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pb-0 mb-0",
                                                  attrs: { md: "4", lg: "6" },
                                                },
                                                [
                                                  _c("v-spacer"),
                                                  _vm.customer.customer
                                                    ? _c("v-textarea", {
                                                        staticClass:
                                                          "text-caption mb-0",
                                                        attrs: {
                                                          outlined: "",
                                                          "persistent-placeholder":
                                                            "",
                                                          label: "Commentaires",
                                                          rows: "3",
                                                          "no-resize": "",
                                                          "hide-details": "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.customer
                                                              .customer.body,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.customer
                                                                .customer,
                                                              "body",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "customer.customer.body",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "v-row",
                                                    { staticClass: "mt-0" },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "py-1 text-center",
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-container",
                                                            [
                                                              _c(
                                                                "v-row",
                                                                {
                                                                  staticClass:
                                                                    "mb-1",
                                                                  attrs: {
                                                                    align:
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              bottom:
                                                                                "",
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "activator",
                                                                                  fn: function ({
                                                                                    on,
                                                                                    attrs,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        _vm._g(
                                                                                          _vm._b(
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  small:
                                                                                                    "",
                                                                                                  text: "",
                                                                                                  color:
                                                                                                    "primary",
                                                                                                },
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.openLinkParent()
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            "v-btn",
                                                                                            attrs,
                                                                                            false
                                                                                          ),
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Comptes liés"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ]
                                                                            ),
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Le compte parent partage ses contacts avec le compte enfant"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-divider"
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm.customer.customer
                                                            ?.parent?.id
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                            attrs,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1 mb-1",
                                                                                      attrs:
                                                                                        {
                                                                                          depressed:
                                                                                            "",
                                                                                          outlined:
                                                                                            "",
                                                                                          rounded:
                                                                                            "",
                                                                                          color:
                                                                                            "indigo",
                                                                                          "x-small":
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.$router.push(
                                                                                              {
                                                                                                name: "Client",
                                                                                                params:
                                                                                                  {
                                                                                                    id: _vm
                                                                                                      .customer
                                                                                                      .customer
                                                                                                      .parent
                                                                                                      .id,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    "v-btn",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .customer
                                                                                        .customer
                                                                                        .parent
                                                                                        .entreprise
                                                                                        .name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1472046789
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Compte parent"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.customer.customer
                                                            .children
                                                            ? _vm._l(
                                                                _vm.customer
                                                                  .customer
                                                                  .children,
                                                                function (
                                                                  children,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      key: i,
                                                                      attrs: {
                                                                        top: "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "activator",
                                                                              fn: function ({
                                                                                on,
                                                                                attrs,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "mr-1 mb-1",
                                                                                          attrs:
                                                                                            {
                                                                                              depressed:
                                                                                                "",
                                                                                              outlined:
                                                                                                "",
                                                                                              rounded:
                                                                                                "",
                                                                                              color:
                                                                                                "green",
                                                                                              "x-small":
                                                                                                "",
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.$router.push(
                                                                                                  {
                                                                                                    name: "Client",
                                                                                                    params:
                                                                                                      {
                                                                                                        id: children.id,
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        "v-btn",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          children
                                                                                            .entreprise
                                                                                            .name
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Compte enfant"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    staticStyle: { "z-index": "1000" },
                    attrs: {
                      headers: _vm.headersSubs,
                      items: _vm.itemsSubs,
                      page: _vm.page,
                      "items-per-page": _vm.limit,
                      loading: _vm.isLoading,
                      "server-items-length": _vm.maxItemsSubs,
                      "footer-props": { disableItemsPerPages: true },
                    },
                    on: {
                      "update:page": _vm.setPage,
                      "update:items-per-page": _vm.setItemMax,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.line.numLicence",
                        fn: function ({ item }) {
                          return _vm._l(item.line, function (line, key) {
                            return _c(
                              "div",
                              { key: key },
                              [
                                line.numLicence
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-1",
                                        attrs: { "x-small": "", label: "" },
                                      },
                                      [_vm._v(_vm._s(line.numLicence))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          })
                        },
                      },
                      {
                        key: "item.line.version",
                        fn: function ({ item }) {
                          return _vm._l(item.line, function (line, key) {
                            return _c(
                              "div",
                              { key: key },
                              [
                                line.version
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "ma-1",
                                        attrs: { "x-small": "", label: "" },
                                      },
                                      [_vm._v(_vm._s(line.version))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          })
                        },
                      },
                      {
                        key: "item.item.startDate",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .dayjs(item.item.startDate)
                                    .format("DD/MM/YYYY")
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.item.endDate",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .dayjs(item.item.endDate)
                                    .format("DD/MM/YYYY")
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.action",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { small: "", text: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.subscriptionSelected = item
                                    _vm.subscriptionModal = true
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(" fa-fw fa-search "),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "footer.prepend",
                        fn: function () {
                          return [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "text-left",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "rounded-1 px-3",
                                        attrs: {
                                          variant: "flat",
                                          color: "primary",
                                          elevation: "0",
                                          depressed: "",
                                          offset: "",
                                          small: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.toggleJotFormModal = true
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { "x-small": "" },
                                          },
                                          [_vm._v("fas fa-plus fa-fw")]
                                        ),
                                        _vm._v(
                                          " Nouvelle proposition abonnement informatique "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._l(_vm.infras, function (infra, key) {
                return _c("v-tab-item", { key: "tab" + key }, [
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "5px",
                            right: "15px",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", fab: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.initField(_vm.headers[infra.id], infra.id)
                                  _vm.addParcModal = true
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fa-plus"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        staticClass: "elevation-0",
                        attrs: {
                          headers: _vm.headers[infra.id],
                          items: _vm.items[key],
                          "items-per-page": 5,
                        },
                        on: { "click:row": _vm.openEdit },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.infra.date",
                              fn: function ({ item }) {
                                return [
                                  item.infra.date
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm
                                                .dayjs(item.infra.date)
                                                .format("DD/MM/YYYY")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.infra.birthday",
                              fn: function ({ item }) {
                                return [
                                  item.infra.birthday
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm
                                                .dayjs(item.infra.birthday)
                                                .format("DD/MM")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                            {
                              key: "item.actions",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: { small: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.newOpp(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" fa-plus fa-fw ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Créer une opportunité"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " fa-info-circle fa-fw"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        item.infra.updatedAt
                                          ? _c("div", [
                                              _c("strong", [
                                                _vm._v(
                                                  "Dernière modification :"
                                                ),
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .dayjs(item.infra.updatedAt)
                                                    .format(
                                                      "DD/MM/YYYY à HH:mm"
                                                    )
                                                ) + " "
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.infra.updatedUser
                                          ? _c(
                                              "div",
                                              [
                                                _c("strong", [_vm._v("Par:")]),
                                                _c("UserAvatar", {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    size: "35",
                                                    userId:
                                                      item.infra.updatedUser.id,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "deleteInfra",
                                                        attrs: {
                                                          small: "",
                                                          color: "error",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.removeInfra(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " fa-fw fa-trash-alt "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Supprimer")])]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c(
                        "v-expansion-panels",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-expansion-panel",
                            { staticClass: "ma-0 pa-0" },
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  staticClass: "ma-0 pa-0",
                                  attrs: { "expand-icon": "mdi-menu-down" },
                                },
                                [
                                  Object.keys(_vm.comments).includes(
                                    infra.id.toString()
                                  ) && _vm.comments[infra.id].body
                                    ? [
                                        _c(
                                          "v-container",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "h3",
                                                      {
                                                        staticClass:
                                                          "mt-0 py-2 mb-3 text-uppercase",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Commentaires parc - " +
                                                            _vm._s(infra.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c("v-divider"),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-caption mt-3",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.comments[
                                                                infra.id
                                                              ].body
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "v-container",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "h3",
                                                      {
                                                        staticClass:
                                                          "mt-0 py-0 mb-1 text-uppercase",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Commentaires parc - " +
                                                            _vm._s(infra.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c("v-divider"),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "text-caption mt-3",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Pas de commentaires"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "v-expansion-panel-content",
                                { staticClass: "ma-0 pa-0" },
                                [
                                  Object.keys(_vm.comments).includes(
                                    infra.id.toString()
                                  )
                                    ? _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          "persistent-placeholder": "",
                                          label: "Commentaire de parc",
                                        },
                                        model: {
                                          value: _vm.comments[infra.id].body,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.comments[infra.id],
                                              "body",
                                              $$v
                                            )
                                          },
                                          expression: "comments[infra.id].body",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          ),
          _c(
            "modal",
            {
              attrs: { "max-width": "1024" },
              scopedSlots: _vm._u(
                [
                  _vm.subscriptionSelected
                    ? {
                        key: "header",
                        fn: function () {
                          return [
                            _c(
                              "strong",
                              { staticClass: "text-h5 mb-0 text-uppercase" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.subscriptionSelected.item.abModele
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.subscriptionSelected.item.abIntitule
                                    )
                                ),
                              ]
                            ),
                            _c("br"),
                            _c(
                              "small",
                              { staticClass: "mt-0" },
                              [
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.customer.entreprise.name) +
                                      " "
                                  ),
                                ],
                              ],
                              2
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
              model: {
                value: _vm.subscriptionModal,
                callback: function ($$v) {
                  _vm.subscriptionModal = $$v
                },
                expression: "subscriptionModal",
              },
            },
            [
              _vm.customer && _vm.subscriptionSelected
                ? _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mb-3" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 text-left",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " Période du " +
                                    _vm._s(
                                      _vm
                                        .dayjs(
                                          _vm.subscriptionSelected.item
                                            .startDate
                                        )
                                        .format("DD/MM/YYYY")
                                    ) +
                                    " au " +
                                    _vm._s(
                                      _vm
                                        .dayjs(
                                          _vm.subscriptionSelected.item.endDate
                                        )
                                        .format("DD/MM/YYYY")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0 text-right",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("v-chip", { attrs: { label: "" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.subscriptionSelected.user) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c("v-simple-table", {
                                attrs: { dense: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c("thead", [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [_vm._v("Article")]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-left" },
                                                [_vm._v("Désignation")]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-right" },
                                                [_vm._v("Quantité")]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-right" },
                                                [_vm._v("Montant HT")]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-right" },
                                                [_vm._v("Numéro licence")]
                                              ),
                                              _c(
                                                "th",
                                                { staticClass: "text-right" },
                                                [_vm._v("Version")]
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "tbody",
                                            _vm._l(
                                              _vm.subscriptionSelected.line,
                                              function (item) {
                                                return _c(
                                                  "tr",
                                                  { key: item.id },
                                                  [
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-left",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.arRef)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-left",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.alDesign)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.alQte)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.alMontantht
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.numLicence
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.version)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1249021483
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "modal",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "strong",
                        { staticClass: "text-h5 mb-0 text-uppercase" },
                        [_vm._v("Affectations métiers")]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: {
                            variant: "flat",
                            color: "primary",
                            elevation: "0",
                          },
                          on: { click: _vm.editAffects },
                        },
                        [_vm._v(" Enregistrer ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "secondary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.modalAffect = false
                            },
                          },
                        },
                        [_vm._v(" Fermer ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.modalAffect,
                callback: function ($$v) {
                  _vm.modalAffect = $$v
                },
                expression: "modalAffect",
              },
            },
            _vm._l(Object.keys(_vm.affects), function (item, key) {
              return _c(
                "v-col",
                { key: key, staticClass: "d-flex", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12" } },
                        [
                          _c("listUser", {
                            attrs: {
                              label: _vm.affects[item].name,
                              multiple: false,
                              includeAll: "",
                              user: _vm.customer.users[item]?.id,
                              usersList: _vm.usersList,
                            },
                            on: {
                              selectChange: (event) =>
                                _vm.setAsUser(event, item),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: _vm.affects[item].color,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.affects[item].icon) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "modal",
            {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "strong",
                        {
                          staticClass:
                            "text-h5 mb-0 text-uppercase affectationGroup",
                        },
                        [_vm._v("Affectations groupes")]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: {
                            variant: "flat",
                            color: "primary",
                            elevation: "0",
                          },
                          on: { click: _vm.editGroups },
                        },
                        [_vm._v(" Enregistrer ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { color: "secondary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.modalGroups = false
                            },
                          },
                        },
                        [_vm._v(" Fermer ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.modalGroups,
                callback: function ($$v) {
                  _vm.modalGroups = $$v
                },
                expression: "modalGroups",
              },
            },
            [
              _c(
                "v-col",
                { staticClass: "d-flex", attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    attrs: {
                      outlined: "",
                      items: _vm.groupsItem,
                      multiple: "",
                      "item-value": "id",
                      "item-text": "name",
                      chips: "",
                      label: "Groupements",
                    },
                    model: {
                      value: _vm.selectedGroups,
                      callback: function ($$v) {
                        _vm.selectedGroups = $$v
                      },
                      expression: "selectedGroups",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("LinkParent", {
            attrs: { customer: _vm.customer },
            on: {
              updateCustomer: _vm.updateCustomer,
              "reload-contact": _vm.reloadContact,
            },
            model: {
              value: _vm.linkParentModal,
              callback: function ($$v) {
                _vm.linkParentModal = $$v
              },
              expression: "linkParentModal",
            },
          }),
        ],
        1
      ),
      _c("jotFormModal", {
        model: {
          value: _vm.toggleJotFormModal,
          callback: function ($$v) {
            _vm.toggleJotFormModal = $$v
          },
          expression: "toggleJotFormModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }