var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      style: _vm.isMobile() ? { marginTop: "50px" } : {},
      attrs: { "max-width": "768" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("strong", { staticClass: "text-h5 mb-0 text-uppercase" }, [
                _vm._v("générer un formulaire d'abonnement"),
              ]),
              _c("br"),
              _c("small", { staticClass: "mt-0" }, [
                _vm._v("Choissisez un contact"),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "rounded-1 px-3",
                  attrs: {
                    loading: _vm.isLoading,
                    variant: "flat",
                    color: "primary",
                    elevation: "0",
                    small: "",
                  },
                  on: { click: _vm.generateJotForm },
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { "x-small": "" } },
                    [_vm._v("fas fa-check fa-fw")]
                  ),
                  _vm._v(" Saisir la proposition "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.isLoading = false
                      _vm.modalVal = false
                    },
                  },
                },
                [_vm._v(" Fermer ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.modalVal,
        callback: function ($$v) {
          _vm.modalVal = $$v
        },
        expression: "modalVal",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "12", lg: "12" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.contacts,
                  "item-text": "name",
                  "item-value": "id",
                  label: "Choissisez un contact",
                  outlined: "",
                  dense: "",
                  "hide-no-data": "",
                  "hide-details": "",
                  "return-object": "",
                },
                model: {
                  value: _vm.selectedContact,
                  callback: function ($$v) {
                    _vm.selectedContact = $$v
                  },
                  expression: "selectedContact",
                },
              }),
            ],
            1
          ),
          _c(
            "v-alert",
            {
              staticClass: "mx-4",
              attrs: { type: "info", outlined: "", dense: "" },
            },
            [
              _vm._v(" Le contact doit avoir une "),
              _c("strong", [_vm._v("adresse email")]),
              _vm._v(" et un "),
              _c("strong", [_vm._v("téléphone")]),
              _vm._v(" valide pour pouvoir générer le formulaire "),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }