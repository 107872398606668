<template>
  <div>
    <v-card style="z-index: 99" elevation="1">
      <v-toolbar color="primary" dark elevation="0" height="90" min-width="100%">
        <v-container fluid v-if="!small">
          <v-row class="mt-1 align-middle">
            <v-col cols="3" lg="2" class="text-left align-self-center d-none d-lg-flex">
              <span class="text-overline text-h6" style="font-size: 0.9rem !important"> Calendrier</span>
            </v-col>
            <v-col cols="12" lg="8" class="text-center text-subtitle-2 align-self-center">
              <v-btn icon class="ma-2" @click="$refs.calendar.prev()" large>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="text-overline text-h6" style="font-size: 0.9rem !important" v-if="typeSelected === 'week'">
                Semaine du {{ dayjs(startDate).format("DD MMMM YYYY") }}
              </span>
              <span class="text-overline" style="font-size: 0.9rem !important" v-if="typeSelected === 'month'">
                {{ dayjs(startDate).format("MMMM YYYY") }}
              </span>
              <span class="text-overline" style="font-size: 0.9rem !important" v-if="typeSelected === 'day'">
                {{ dayjs(startDate).format("DD MMMM YYYY") }}
              </span>
              <v-btn icon large class="ma-2" @click="$refs.calendar.next()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="3" lg="2" class="text-right align-self-center d-none d-lg-flex">
              <v-row>
                <v-select
                  v-model="typeSelected"
                  :items="types"
                  item-text="name"
                  item-value="value"
                  hide-details
                  outlined
                  dense
                  class="mx-2"
                  label="Affichage"
                ></v-select>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <div class="listbox--actions-add">
          <v-tooltip bottom :key="key" v-for="(item, key) in eventTypeArray">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                fab
                class="mr-2"
                :color="item.color"
                @click="
                  newEventFct();
                  newEvent.eventtypeId = item.id;
                  newEvent.teams = item.teams;
                  newEvent.ewsToggle = item.ewsCalendar;
                  newEvent.body = item.defaultText;
                  newEvent.duration = item.duration;
                "
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> {{ item.icon }} fa-fw </v-icon>
              </v-btn>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </div>
        <div class="modal-menu--actions">
          <v-btn text v-if="isGranted('EVENTS_INDEX_ALL')" @click="editUserList = true" small>
            <v-icon small> fa-cog fa-fw </v-icon>
          </v-btn>
        </div>
      </v-toolbar>

      <modal v-model="editUserList" v-if="isGranted('EVENTS_INDEX_ALL')">
        <template v-slot:header>
          <strong class="text-h5 mb-0 text-uppercase">Filtre</strong>
          <br /><small class="mt-0">Choix du commercial</small>
        </template>
        <listUser @selectChange="setAsUser"></listUser>
      </modal>

      <v-sheet v-if="isGranted('EVENTS_INDEX')">
        <v-calendar
          ref="calendar"
          v-model="valueCal"
          :event-color="getEventColor"
          :event-ripple="false"
          :events="events"
          :weekdays="weekday"
          :type="typeSelected"
          :loading="loadingDrag"
          @change="getEvents"
          :start="start"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          first-time="08:00"
          interval-minutes="30"
          interval-count="22"
          @click:time="addEventHere"
          @click:date="typeSelected = 'day'"
          :style="this.typeSelected != 'month' ? 'max-height: calc(100vh - 165px )' : ''"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mouseleave.native="preventLeaveArea"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
        >
          <template v-slot:event="{ event, timed }">
            <div class="v-event-draggable" :style="typeSelected === 'month' ? 'cursor: default;' : 'cursor: move; height: 100%;'">
              <span v-html="getState(event.init)"></span>
              <strong :style="event.init.status != 0 ? 'text-decoration: line-through;color:#494949;' : 'color:#494949;'">
                {{ event.name }}
              </strong>
              <v-icon
                v-if="typeSelected !== 'month' && !eventsEdits[event.init.id]"
                class=""
                small
                @click.stop="showEvent(event)"
                style="position: absolute; top: 5px; right: 5px; cursor: pointer"
              >
                fa-fw fa-edit
              </v-icon>
              <br v-if="typeSelected != 'month'" />
              <span style="min-width: 2em"> </span>
              <span class="grey--text text--lighten-4 ms-4" style="font-size: 0.95em">
                {{ dayjs(event.start).format("HH:mm") }} - {{ dayjs(event.end).format("HH:mm") }}
              </span>
            </div>
            <div v-if="eventsEdits[event.init.id]" style="position: absolute; bottom: 10px; right: 10px">
              <v-btn x-small @click.stop="cancelDrag(event)" color="error" class="mr-1">
                <v-icon x-small>fa-times</v-icon>
              </v-btn>
              <v-btn :loading="loadingDrag" x-small @click="saveDrag(event.init.id)" color="accent">
                <v-icon x-small>fa-save</v-icon>
              </v-btn>
            </div>
            <div v-if="timed && typeSelected !== 'month'" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)"></div>
          </template>
        </v-calendar>
        <v-menu v-if="selectedEvent && false" v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-btn icon @click="openEditModal()">
                <v-icon small>fa-pen</v-icon>
              </v-btn>
              <v-btn icon @click="removeEvent">
                <v-icon small>fa-trash</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text v-if="selectedEvent && selectedEvent.init">
              <span v-html="selectedEvent.init.body"></span>
              <div v-if="selectedEvent && globalResp && globalResp.entreprise[selectedEvent.init.id]">
                <span>Client: {{ globalResp.entreprise[selectedEvent.init.id].name }}</span>
              </div>
              <div>
                <span>Début: {{ dayjs(selectedEvent.init.start).format("DD-MM-YYYY à HH:mm") }}</span>
              </div>
              <div>
                <span>Fin: {{ dayjs(selectedEvent.init.end).format("DD-MM-YYYY à HH:mm") }}</span>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn text :color="selectedEvent.color" @click="selectedOpen = false"> Fermer </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>

      <modal v-model="createModal" max-width="1024">
        <template v-slot:header v-if="newEvent && newEvent.customerId">
          <strong class="text-h5 mb-0 text-uppercase">{{ newEvent && newEvent.id ? "Édition" : "Création" }} Evènement</strong>
          <br /><small class="mt-0">
            <template v-if="newEvent"> {{ dayjs(newEvent.date).format("DD/MM/YYYY") }} {{ newEvent.time }} - {{ newEvent.name }} </template>
          </small>
          <template v-if="newEvent">
            <template v-if="newEvent.id">
              <div
                style="
                  position: absolute;
                  top: 0;
                  left: 50%;
                  background: #78909c;
                  padding: 5px;
                  translate: -50% 0;

                  border-radius: 0 0 5px 5px;
                "
              >
                <v-row>
                  <v-col cols="12" class="text-left">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          elevation="0"
                          plain
                          color="primary"
                          @click="$router.push({ name: 'Client', params: { id: newEvent.customerId } })"
                        >
                          <v-icon x-small>fa-search</v-icon>
                        </v-btn>
                      </template>
                      <span>Fiche client</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="ml-1"
                          small
                          elevation="0"
                          plain
                          color="none"
                          @click="
                            typeLink.id = newEvent.id;
                            linkModal = true;
                          "
                        >
                          <v-icon x-small>fa-envelope</v-icon>
                        </v-btn>
                      </template>
                      <span>Lier un e-mail</span>
                    </v-tooltip>

                    <template v-if="newEvent.opportunityId">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-1"
                            elevation="0"
                            small
                            plain
                            color="warning"
                            @click="$router.push({ name: 'Opportunité', params: { id: newEvent.opportunityId } })"
                          >
                            <v-icon x-small>fa-handshake</v-icon>
                          </v-btn>
                        </template>
                        <span>Opportunité {{ newEvent.opportunityId }}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="newOpp" v-bind="attrs" v-on="on" class="mx-1" elevation="0" small plain color="warning">
                            <v-icon x-small>fa-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Créer opportunité</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" @click="linkOppModal = true" small plain elevation="0">
                            <v-icon x-small>fa-link</v-icon>
                          </v-btn>
                        </template>
                        <span>Associer opportunité</span>
                      </v-tooltip>
                    </template>
                  </v-col>
                </v-row>
              </div>
            </template>
          </template>
        </template>
        <template v-if="newEvent && newEvent.customerId !== null">
          <v-form v-model="valid" ref="form">
            <commentFormOpportunities v-model="newEvent"></commentFormOpportunities>
          </v-form>
        </template>
        <template v-else>
          <v-row>
            <v-col cols="12">
              Rechercher un compte
              <v-autocomplete
                :menu-props="{ maxHeight: '300' }"
                style="z-index: 999"
                v-model="selectedCustomer"
                :items="customersList"
                :loading="isLoading"
                :search-input.sync="search"
                hide-no-data
                hide-selected
                item-text="entreprise.name"
                item-value="customer.id"
                label="Rechercher un compte"
                prepend-icon="fa-users"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
        <template v-slot:actions>
          <v-btn v-if="newEvent && newEvent.customerId !== null" variant="flat" color="primary" elevation="0" @click="addEventFct" :loading="loadingSave">
            Enregistrer
          </v-btn>
          <v-btn v-else variant="flat" color="primary" elevation="0" @click="newEvent.customerId = selectedCustomer"> Suivant </v-btn>
          <v-btn color="secondary" text class="mr-4" @click="createModal = false"> Fermer </v-btn>
        </template>
      </modal>

      <modal v-model="editModal" max-width="1024">
        <template v-slot:header>
          <strong class="text-h5 mb-0 text-uppercase">{{ newEvent && newEvent.id ? "Édition" : "Ajout" }} Evènement</strong>
          <br /><small class="mt-0">
            <template v-if="newEvent"> {{ dayjs(newEvent.date).format("DD/MM/YYYY") }} {{ newEvent.time }} - {{ newEvent.name }} </template>
          </small>
          <template v-if="newEvent">
            <template v-if="newEvent.id">
              <div style="position: absolute; top: 0; left: 50%; background: #78909c; padding: 5px; translate: -50% 0; border-radius: 0 0 5px 5px">
                <v-row>
                  <v-col cols="12" class="text-left">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          elevation="0"
                          plain
                          color="primary"
                          @click="$router.push({ name: 'Client', params: { id: newEvent.customerId } })"
                        >
                          <v-icon x-small>fa-search</v-icon>
                        </v-btn>
                      </template>
                      <span>Fiche client</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="ml-1"
                          small
                          elevation="0"
                          plain
                          color="none"
                          @click="
                            typeLink.id = newEvent.id;
                            linkModal = true;
                          "
                        >
                          <v-icon x-small>fa-envelope</v-icon>
                        </v-btn>
                      </template>
                      <span>Lier un e-mail</span>
                    </v-tooltip>

                    <template v-if="newEvent.opportunityId">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="mx-1"
                            elevation="0"
                            small
                            plain
                            color="warning"
                            @click="$router.push({ name: 'Opportunité', params: { id: newEvent.opportunityId } })"
                          >
                            <v-icon x-small>fa-handshake</v-icon>
                          </v-btn>
                        </template>
                        <span>Opportunité {{ newEvent.opportunityId }}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="newOpp" v-bind="attrs" v-on="on" class="mx-1" elevation="0" small plain color="warning">
                            <v-icon x-small>fa-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Créer opportunité</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" @click="linkOppModal = true" small plain elevation="0">
                            <v-icon x-small>fa-link</v-icon>
                          </v-btn>
                        </template>
                        <span>Associer opportunité</span>
                      </v-tooltip>
                    </template>
                  </v-col>
                </v-row>
              </div>
            </template>
          </template>
        </template>

        <v-form ref="formEdit" v-model="valid">
          <commentFormOpportunities v-model="newEvent"></commentFormOpportunities>
        </v-form>
        <template v-slot:actions>
          <v-btn variant="flat" color="primary" elevation="0" @click="editEventFct" :loading="loadingSave"> Enregistrer </v-btn>
          <v-btn color="secondary" text class="mr-4" @click="editModal = false"> Fermer </v-btn>
        </template>
      </modal>
    </v-card>
  </div>
</template>

<script>
import dayjs from "dayjs";

import Modal from "../../components/common/modal.vue";
import ListUser from "../../components/user/listUser";
import CommentFormOpportunities from "../../components/common/EventForm";
import Tools from "../../mixins/Tools";
import simpleDebounce from "simple-debounce-vue-mixin";
import EventsTools from "../../mixins/Events";
import MobileDetection from "../../mixins/MobileDetection";

export default {
  name: "Calendar",
  components: { CommentFormOpportunities, ListUser, Modal },
  mixins: [Tools, simpleDebounce, EventsTools, MobileDetection],
  props: {
    type: {
      type: String,
      default: "week",
    },
    small: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    typeSelected: "week",
    search: "",
    isLoading: true,
    selectedCustomer: null,
    customersList: [],
    createModal: false,
    valid: false,
    mode: "column",
    weekday: [1, 2, 3, 4, 5],
    types: [
      {
        name: "Mois",
        value: "month",
      },
      {
        name: "Semaine",
        value: "week",
      },
      {
        name: "Jour",
        value: "day",
      },
    ],
    start: dayjs().format("YYYY-MM-DD HH:mm"),
    valueCal: "",
    events: [],
    addDay: 5,
    selectedOpen: false,
    selectedEvent: {},
    selectedElement: null,
    editModal: false,
    editAction: null,
    menuTime: false,
    activePicker: null,
    menuDate: false,
    typeEvent: [
      {
        id: 1,
        name: "Phoning",
      },
      {
        id: 2,
        name: "Rendez-vous",
      },
    ],
    statusEvent: [
      {
        id: 0,
        name: "En attente",
      },
      {
        id: 1,
        name: "Réalisé",
      },
      {
        id: 2,
        name: "Annulé",
      },
    ],
    asUser: [],
    asUserEv: [],
    startDate: null,
    editUserList: false,
    newEvent: {},
    opp: null,
    globalResp: null,
    loadingSave: false,
    colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1"],
    names: ["Meeting", "Holiday", "PTO", "Travel", "Event", "Birthday", "Conference", "Party"],
    eventTypeArray: [],
    loadingDrag: false,
    dragEvent: null,
    dragStart: null,
    extendedEvent: null,
    extendedStart: null,
    extendOriginal: null,
    eventsEdits: {},
  }),
  created() {
    if (this.typeSelected === "month") {
      this.addDay = 31;
    }
  },
  mounted() {
    this.typeSelected = !MobileDetection.methods.isMobile() ? this.type : "day";
    this.asUser = this.value;
    this.getTypeEvent();
  },
  methods: {
    extendBottom(event) {
      if (this.typeSelected === "month") return;
      this.extendedEvent = event;
      this.extendedStart = dayjs(event.start).valueOf();
      this.extendOriginal = event.end;
    },
    cancelDrag(event) {
      event.start = dayjs(event.init.start).format("YYYY-MM-DD HH:mm");
      event.end = dayjs(event.init.end).format("YYYY-MM-DD HH:mm");
      event.date = dayjs(event.init.start).format("YYYY-MM-DD");
      this.eventsEdits[event.init.id] = null;
      this.dragTime = null;
      this.dragEvent = null;
      this.extendedEvent = null;
      this.extendedStart = null;
    },
    startDrag({ event, timed }) {
      if (this.typeSelected === "month") {
        this.showEvent(event);
        return;
      }
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = dayjs(this.dragEvent.start).valueOf();

        if (!isNaN(start)) {
          this.dragTime = mouse - start;
        } else {
          console.error("Invalid start date in startTime");
        }
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime) {
        const start = dayjs(this.dragEvent.start).valueOf();
        const end = dayjs(this.dragEvent.end).valueOf();

        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        if (!isNaN(newStart) && !isNaN(newEnd)) {
          this.dragEvent.start = dayjs(newStart).format("YYYY-MM-DD HH:mm");
          this.dragEvent.end = dayjs(newEnd).format("YYYY-MM-DD HH:mm");
        } else {
          console.error("Invalid date calculation in mouseMove");
        }
      } else if (this.extendedEvent && this.extendedStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.extendedStart);
        const max = Math.max(mouseRounded, this.extendedStart);

        this.extendedEvent.start = dayjs(min).format("YYYY-MM-DD HH:mm");
        this.extendedEvent.end = dayjs(max).format("YYYY-MM-DD HH:mm");
      }
    },
    async endDrag() {
      if (this.extendedEvent) {
        this.dragEvent = this.extendedEvent;
      }
      if (!dayjs(this.dragEvent.start).isSame(this.dragEvent.init.start) || !dayjs(this.dragEvent.end).isSame(this.dragEvent.init.end)) {
        this.eventsEdits[this.dragEvent.init.id] = this.dragEvent;
      } else {
        this.eventsEdits[this.dragEvent.init.id] = null;
      }
      this.extendOriginal = null;
      this.extendedEvent = null;
      this.extendedStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    async saveDrag(id) {
      this.loadingDrag = true;
      const payloadEvent = {
        ...this.eventsEdits[id].init,
        date: dayjs(this.eventsEdits[id].start).format("YYYY-MM-DD"),
        start: dayjs(this.eventsEdits[id].start),
        end: dayjs(this.eventsEdits[id].end),
        duration: dayjs(this.eventsEdits[id].end).diff(dayjs(this.eventsEdits[id].start), "minute"),
        contacts: this.globalResp.info[id].map((item) => item.contactId),
        user: this.globalResp.users[id].map((item) => item.userId),
      };
      await this.$axios.patch("/api/events/" + payloadEvent.id, { newEvent: payloadEvent }).then(() => {
        this.$simpleToast.success("Evènement", "Modifications enregistrées");
      });
      this.loadEvent(this.startDate);
      this.eventsEdits[id] = null;
      this.loadingDrag = false;
    },
    preventLeaveArea() {
      this.dragTime = null;
      this.dragEvent = null;
      this.extendedEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime();
    },

    returnName(item) {
      let name = "";
      switch (item.input.init.status) {
        case 0:
          name += "<i class='far fa-pause-circle fa-fw grey--text'></i> ";
          break;
        case 1:
          name += "<i class='far fa-check-circle fa-fw success--text'></i> ";
          break;
        case 2:
          name += "<i class='far fa-check-circle fa-fw error--text'></i> ";
          break;
      }
      if (item.input.init.teams) {
        name += '<img src="/images/microsoft-teams.svg" style="max-width: 10px;" class="ms-1 me-2 "/>';
      }
      name += item.input.name;

      return name;
    },

    getState(item) {
      let name = "";
      switch (item.status) {
        case 0:
          name += "<i class='far fa-pause-circle fa-fw grey--text'></i> ";
          break;
        case 1:
          name += "<i class='far fa-check-circle fa-fw success--text'></i> ";
          break;
        case 2:
          name += "<i class='far fa-check-circle fa-fw error--text'></i> ";
          break;
      }
      if (item.teams) {
        name += '<img src="/images/microsoft-teams.svg" style="max-width: 10px;" class="ms-1 me-2 "/>';
      }

      return name;
    },
    loadSearch() {
      this.isLoading = true;
      let search = {
        name: this.search,
        isIndirect: null,
        siret: null,
        postalCode: null,
        city: null,
        sector: null,
        phone: null,
        ctNumAb: null,
        ape: null,
        sortedBy: null,
        sortedDesc: false,
      };

      this.$axios
        .get("/api/customers", {
          params: {
            page: 1,
            search,
            limit: 10,
            asUser: [],
            userMode: "tous",
            typeMode: "tous",
            onlyClient: true,
            onlyProspect: true,
          },
        })
        .then((res) => {
          this.customersList = res.data.data;
          this.isLoading = false;
        });
    },
    newOpp() {
      this.$axios.post("/api/opportunities", { event: this.newEvent }).then((res) => {
        this.linkOppFct(res.data.opp.id);
        this.$router.push({ name: "Opportunité", params: { id: res.data.opp.id } });
      });
    },
    addEventHere(event) {
      const clickedTime = dayjs(`${event.date} ${event.time}`, "YYYY-MM-DD HH:mm");
      const existingEvent = this.events.find((e) => {
        const eventStart = dayjs(e.start, "YYYY-MM-DD HH:mm");
        const eventEnd = dayjs(e.end, "YYYY-MM-DD HH:mm");

        return clickedTime.isBetween(eventStart, eventEnd, null, "[)");
      });

      if (existingEvent) return;

      this.newEvent = {
        customerId: null,
        eventtypeId: null,
        name: "",
        time: event.hour + ":" + "00",
        date: event.date,
        duration: 30,
        private: false,
        status: 0,
        teams: false,
        body: "",
        contacts: [],
        customer: this.asUser,
        history: [],
      };
      this.selectedCustomer = null;
      this.search = "";
      this.createModal = true;
    },
    setOpp(event) {
      this.opp = event;
    },
    async addEventFct() {
      let res = false;
      this.$refs.form.validate();
      if (!this.valid) {
        this.$simpleToast.error("Evènement", "Un des champs est vide.");
      } else {
        this.loadingSave = true;
        res = await this.addEventSubmit(this.newEvent);
        if (res) {
          this.loadEvent(this.startDate);
          this.newEvent = null;
          this.loadingSave = false;
          this.createModal = false;
          this.editModal = false;
        }
        this.loadingSave = false;
      }
    },
    async removeEvent() {
      const res = await this.$confirm("Êtes-vous sur ?", { title: "Confirmation", color: "primary" });
      if (res) {
        this.$axios.delete("/api/events/" + this.selectedEvent.init.event.id).then(() => {
          this.getEvents();
        });
      }
    },
    openAddModal() {
      this.initNewEvent();
      this.createModal = true;
    },
    initNewEvent() {
      this.newEvent = {
        eventtypeId: null,
        name: "",
        time: null,
        date: null,
        duration: 0,
        status: false,
        body: "",
        contacts: [],
        customer: this.asUser,
      };
    },
    setAsUser(event) {
      this.asUser = event;
    },
    setAsUserEv(event) {
      this.asUserEv = event;
    },
    newEventFct() {
      this.newEvent = {
        customerId: null,
        eventtypeId: null,
        name: "",
        time: null,
        date: null,
        duration: 30,
        private: false,
        status: 0,
        teams: false,
        body: "",
        contacts: [],
        customer: this.asUser,
        history: [],
      };
      this.selectedCustomer = null;
      this.search = "";
      this.createModal = true;
    },
    async editEventFct() {
      let res = false;
      this.$refs.formEdit.validate();
      if (this.valid) {
        res = await this.editEventSubmit(this.newEvent);
        this.loadingSave = false;
      }

      if (res) {
        this.addEvent = false;
        this.editModal = false;
        this.newEvent = null;
        this.loadEvent(this.startDate);
      }
    },
    openEditModal() {
      this.editAction = null;
      this.editAction = JSON.parse(JSON.stringify(this.selectedEvent.init.event));
      this.editAction.date = dayjs(this.selectedEvent.init.event.start).format("YYYY-MM-DD");
      this.editAction.time = dayjs(this.selectedEvent.init.event.start).format("HH:mm");
      this.editAction.contactsList = this.selectedEvent.init.customer.contacts;
      this.editAction.contacts = this.selectedEvent.init.contacts;
      this.editModal = true;
    },
    showEvent(event) {
      let item = JSON.parse(JSON.stringify(event.init));

      let dateStart = dayjs(item.start).format("DD/MM/YYYY");
      let timeStart = dayjs(item.start).format("HH:mm");
      this.newEvent = {};
      let tmpContacts = [];
      this.globalResp.info[item.id].forEach((item) => {
        if (item.contactId) tmpContacts.push(item.contactId);
      });
      this.newEvent = {
        id: item.id,
        eventtypeId: item.eventtypeId,
        opportunityId: item.opportunity ? item.opportunity.id : null,
        name: item.name,
        time: timeStart,
        date: dateStart,
        usersList: this.globalResp.users[item.id],
        duration: item.duration,
        private: item.private,
        status: item.status,
        body: item.body,
        teams: item.teams,
        contacts: tmpContacts,
        ewsid: item.ewsid,
        idMS: item.ewsid,
        customer: item.customerId,
        customerId: item.customerId,
        history: [],
        report: item.report,
      };
      this.editModal = true;
    },
    loadEvent(start) {
      let events = [];
      this.globalResp = {
        events: [],
        customers: {},
        email: {},
        info: {},
        type: {},
        users: {},
      };

      let startDate = dayjs(start);
      switch (this.typeSelected) {
        case "month":
          this.addDay = 31;
          break;
        case "week":
          this.addDay = 5;
          break;
        default:
          this.addDay = 1;
          break;
      }

      this.$axios(`/api/events/user/calendar`, {
        params: {
          startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
          endDate: startDate.add(this.addDay, "day").format("YYYY-MM-DD HH:mm:ss"),
          asUser: this.asUser,
        },
      }).then((res) => {
        let pushitem = {};

        if (typeof res.data.entreprise === "object") this.globalResp.entreprise = { ...this.globalResp.entreprise, ...res.data.entreprise };

        if (typeof res.data.email === "object") this.globalResp.email = { ...this.globalResp.email, ...res.data.email };

        if (typeof res.data.info === "object") this.globalResp.info = { ...this.globalResp.info, ...res.data.info };

        if (typeof res.data.type === "object") this.globalResp.type = { ...this.globalResp.type, ...res.data.type };

        if (typeof res.data.users === "object") this.globalResp.users = { ...this.globalResp.users, ...res.data.users };

        if (res.data.events.length > 0) {
          res.data.events.forEach((item) => {
            this.globalResp.events.push(item);
            pushitem = {
              name: res.data.entreprise[item.id] ? res.data.entreprise[item.id].name : "ERR",
              start: dayjs(item.start).format("YYYY-MM-DD HH:mm"),
              end: dayjs(item.end).format("YYYY-MM-DD HH:mm"),
              date: dayjs(item.start).format("YYYY-MM-DD"),
              color: res.data.type[item.eventtypeId].color,
              init: item,
              timed: true,
            };
            events.push(pushitem);
          });
        }

        this.events = events;
      });
    },
    getEvents({ start }) {
      this.startDate = start.date;
      this.loadEvent(this.startDate);
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent ? `rgba(${r}, ${g}, ${b}, 0.7)` : event === this.extendedEvent ? `rgba(${r}, ${g}, ${b}, 0.7)` : event.color;
    },

    saveDate(date) {
      this.$refs.menu.save(date);
    },
    getTypeEvent() {
      this.eventTypeArray = [];
      this.$axios("/api/events/types").then((resp) => {
        let ev = resp.data;

        ev.forEach((e) => {
          if (e.shortcut) {
            this.eventTypeArray.push(e);
          }
        });

        //  this.eventTypeArray = resp.data;
      });
    },
    linkOppFct(idOpp) {
      this.$axios.patch("/api/events/" + this.newEvent.id + "/link", { opp: idOpp }).then(() => {
        this.linkOppModal = false;
        this.addEvent = false;
        this.getEvents();
        this.$simpleToast.success("Evènement", "L'élément a bien été lié");
      });
    },
  },
  watch: {
    search() {
      this.simpleDebounce(() => {
        this.loadSearch();
      });
    },
    value() {
      this.asUser = this.value;
    },
    asUser() {
      this.loadEvent(this.startDate);
    },
    type() {
      switch (this.type) {
        case "week":
          this.addDay = 5;
          break;
        case "day":
          this.addDay = 1;
          break;
        case "month":
          this.addDay = 30;
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: block;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }
}
</style>
