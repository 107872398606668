<template>
  <v-row v-if="item" class="mt-6">
    <modal v-model="readCommentModal"> </modal>
    <v-col :cols="isGranted('OPPORTUNITIES_DELETE') ? 10 : 11" style="position: relative">
      <sui-step-group style="width: 100%; margin-top: -50px">
        <sui-step class="pa-2 text-left" style="cursor: pointer; justify-content: left" :active="item && item.opp.stepId >= 1" :disabled="!item.opp.opened">
          <span class="float-start ml-1" v-if="item && item.opp.stepId >= 1 && item.opp.createdAt && item.opp.userId !== null">
            <UserAvatar :userId="getUserIdFromStep(1) ? getUserIdFromStep(1) : item.opp.userId" size="45" />
          </span>

          <sui-step-content class="ml-2">
            <v-row>
              <v-col cols="12">
                <sui-step-title class="font-weight-regular text-uppercase text-subtitle-1">
                  <v-icon small>fa-fingerprint fa-fw</v-icon>
                  Identifié</sui-step-title
                >
                <sui-step-description class="pl-6 mt-0 grey--text text--lighten-3 text-subtitle-2">
                  {{ dayjs(item.opp.createdAt).format("DD/MM/YYYY") }}
                  <span class="d-none d-lg-inline">{{ dayjs(item.opp.createdAt).format(" à HH:mm") }}</span>
                </sui-step-description>
              </v-col>
            </v-row>
          </sui-step-content>
        </sui-step>

        <sui-step
          style="cursor: pointer; justify-content: left"
          class="pa-2"
          :disabled="!item.opp.opened"
          :active="item && item.opp.stepId >= 2"
          @click="item && item.opp.stepId !== 2 ? setProgression(50) : setProgression(25)"
        >
          <span class="float-start ml-1" v-if="item && item.opp.stepId >= 2">
            <UserAvatar :userId="getUserIdFromStep(2)" size="45" />
          </span>
          <sui-step-content class="ml-2">
            <v-row>
              <v-col cols="12">
                <sui-step-title class="font-weight-regular text-uppercase text-subtitle-1">
                  <v-icon small>fa-user-graduate fa-fw</v-icon>
                  Étude
                </sui-step-title>
                <sui-step-description v-if="item && item.opp.stepId >= 2" style="color: white" class="pl-6 mt-0 grey--text text--lighten-3 text-subtitle-2">
                  {{ dayjs(returnStepDatetime(2)).format("DD/MM/YYYY") }}
                  <span class="d-none d-lg-inline">{{ dayjs(returnStepDatetime(2)).format(" à HH:mm") }}</span>
                </sui-step-description>
                <sui-step-description v-else style="color: white" class="pl-6">&nbsp; </sui-step-description>
              </v-col>
            </v-row>
          </sui-step-content>
        </sui-step>

        <sui-step
          style="cursor: pointer; justify-content: left"
          class="pa-2"
          :disabled="!item.opp.opened"
          :active="item && item.opp.stepId >= 3"
          @click="item && item.opp.stepId !== 3 ? setProgression(75) : setProgression(50)"
        >
          <span class="float-start ml-1" v-if="item && item.opp.stepId >= 3">
            <UserAvatar :userId="getUserIdFromStep(3)" size="45" />
          </span>
          <sui-step-content class="ml-2">
            <sui-step-title class="font-weight-regular text-uppercase text-subtitle-1">
              <v-icon small>far fa-handshake fa-fw</v-icon>

              Négociation
            </sui-step-title>
            <sui-step-description v-if="item && item.opp.stepId >= 3" style="color: white" class="pl-6 mt-0 grey--text text--lighten-3 text-subtitle-2">
              {{ dayjs(returnStepDatetime(3)).format("DD/MM/YYYY") }}
              <span class="d-none d-lg-inline">{{ dayjs(returnStepDatetime(3)).format(" à HH:mm") }}</span>
            </sui-step-description>
            <sui-step-description v-else style="color: white" class="pl-6">&nbsp; </sui-step-description>
          </sui-step-content>
        </sui-step>

        <sui-step
          style="cursor: pointer; justify-content: left"
          :active="!item.opp.opened"
          @click="item.opp.opened ? setStatus(!item.opp.opened) : false"
          class="pa-2"
        >
          <span class="float-start ml-1" v-if="item && !item.opp.opened">
            <UserAvatar :userId="item.opp.closedUserId" size="45" />
          </span>
          <sui-step-content class="ml-2">
            <sui-step-title class="font-weight-regular text-uppercase text-subtitle-1">
              <v-icon x-small v-if="item && item.opp.stepId < 4"> {{ item.opp.opened ? "fa-lock-open" : "fa-lock" }} fa-fw </v-icon>
              {{ item.opp.opened ? "Ouvert" : getStatus(item.opp.statusId) }}
              <v-tooltip bottom v-if="!item.opp.opened">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" x-small> fa-info-circle fa-fw </v-icon>
                </template>
                <span>{{ item.opp.bodyClose }}</span>
              </v-tooltip>
            </sui-step-title>
            <sui-step-description v-if="item && !item.opp.opened" class="pl-6 mt-0 grey--text text--lighten-3 text-subtitle-2">
              <template v-if="item.win && item.win.name"> <v-icon small>fa-trophy fa-fw</v-icon> {{ item.win.name }} - </template>
              {{ dayjs(item.opp.closedDatetime).format("DD/MM/YYYY") }}
              <span class="d-none d-lg-inline">{{ dayjs(item.opp.closedDatetime).format(" à HH:mm") }}</span>
            </sui-step-description>
            <sui-step-description v-else style="color: white" class="pl-6">&nbsp; </sui-step-description>
          </sui-step-content>
          <v-btn text x-small style="z-index: 11; position: absolute; right: 5px; top: 5px" v-if="!item.opp.opened">
            <v-icon @click="infoModal = true" small>fa-retweet fa-fw</v-icon>
          </v-btn>
        </sui-step>
      </sui-step-group>
    </v-col>

    <v-col :cols="isGranted('OPPORTUNITIES_DELETE') ? 2 : 1" class="py-0" style="margin-top: 0.7rem">
      <v-row>
        <v-col>
          <UserAvatar :userId="item.opp.userId" size="35" />
          <div
            class="float-lg-right"
            :style="
              isMobile()
                ? {
                    marginTop: '10px',
                  }
                : {}
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  class="ml-md-1 transfertOpp"
                  :class="isMobile() ? 'mb-1' : ''"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="modalTransf = true"
                  :disabled="!item.entreprise"
                >
                  <v-icon> fa-exchange-alt fa-fw </v-icon>
                </v-btn>
              </template>
              <span>Transfert</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isGranted('OPPORTUNITIES_DELETE')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab class="ml-md-3 deleteOpp" x-small v-bind="attrs" v-on="on" color="error" @click="deleteOpp">
                  <v-icon>fa-trash fa-fw</v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <modal v-model="modalTransf">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Transfert</strong>
      </template>
      <listUser @selectChange="setAsUser" includeAll :multiple="false"></listUser>
      <template v-slot:actions>
        <v-btn variant="flat" color="primary" elevation="0" class="mr-4" @click="transfert"> Valider </v-btn>
        <v-btn color="secondary" text class="mr-4" @click="modalTransf = false"> Fermer </v-btn>
      </template>
    </modal>
    <modal v-model="infoModal">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Date de relance"
                placeholder="Date de relance"
                persistent-hint
                v-bind="attrs"
                @blur="date = parseDate(dateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker first-day-of-week="1" v-model="date" no-title @input="menu1 = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-btn color="error" text @click="infoModal = false"> Fermer </v-btn>
        <v-btn text class="mr-4" @click="validateRelance" :disabled="closeOppLoading"> Enregistrer </v-btn>
      </template>
    </modal>
    <modal v-model="dialog">
      <template v-slot:header> Cloturer l'opportunité </template>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="6" v-for="(status, key) in statuses" :key="key">
            <v-switch v-model="state" :label="status.name" :value="status.id"></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="state === 2 && item.concurrents.length > 0">
          <v-col cols="12"> Concurrent ayant {{ state === 2 ? "remporté" : "perdu" }} l'opportunité </v-col>
          <v-col cols="6" v-for="(concu, key) in item.concurrents" :key="key">
            <v-switch v-model="concuWin" :label="concu.name" :value="concu.concurrents.id"></v-switch>
          </v-col>
        </v-row>

        <v-textarea name="input-7-1" label="Commentaire" value="" v-model="comments"></v-textarea>
      </v-form>
      <template v-slot:actions>
        <v-btn color="error" @click="dialog = false"> Fermer </v-btn>
        <v-btn class="mr-4" @click="validate" :disabled="closeOppLoading"> Enregistrer </v-btn>
      </template>
    </modal>
  </v-row>
</template>

<script>
import Modal from "../../components/common/modal";
import Tools from "../../mixins/Tools";
import ListUser from "../../components/user/listUser";
import UserAvatar from "../../components/user/UserAvatar.vue";
import MobileDetection from "../../mixins/MobileDetection";
import dayjs from "dayjs";
import StepGroup from "semantic-ui-vue/dist/commonjs/elements/Step/StepGroup";
import StepContent from "semantic-ui-vue/dist/commonjs/elements/Step/StepContent";
import StepDescription from "semantic-ui-vue/dist/commonjs/elements/Step/StepDescription";
import StepTitle from "semantic-ui-vue/dist/commonjs/elements/Step/StepTitle";
import Step from "semantic-ui-vue/dist/commonjs/elements/Step/Step";
import "semantic-ui-css/semantic.min.css";

export default {
  name: "stepsOpportunities",
  mixins: [Tools, MobileDetection],
  components: {
    ListUser,
    Modal,
    "sui-step-group": StepGroup,
    "sui-step-content": StepContent,
    "sui-step": Step,
    "sui-step-title": StepTitle,
    "sui-step-description": StepDescription,
    UserAvatar,
  },
  props: ["value"],
  data() {
    return {
      concuWin: null,
      closeOppLoading: false,
      readCommentModal: false,
      statuses: [],
      modalTransf: false,
      step: 0,
      item: null,
      dialog: false,
      valid: true,
      state: 1,
      comments: "",
      infoModal: false,
      date: dayjs().format("YYYY-MM-DD"),
      dateFormatted: this.formatDate(dayjs().format("YYYY-MM-DD")),
      menu1: false,
      transfUsr: null,
      oppSteps: [],
    };
  },
  mounted() {
    this.getSteps();
    this.getStatuses();
  },
  methods: {
    getSteps() {
      this.$axios("/api/opportunities/" + this.$route.params.id + "/step").then((resp) => {
        this.oppSteps = resp.data;
      });
    },
    returnStepDatetime(step) {
      let ret = 0;
      this.oppSteps.forEach((item) => {
        if (item.stepId === step) {
          ret = item.createdAt;
        }
      });
      return ret;
    },
    getUserIdFromStep(step) {
      let ret = 0;
      this.oppSteps.forEach((item) => {
        if (item.stepId === step) {
          ret = item.userId;
        }
      });
      return ret;
    },
    getStatuses() {
      this.$axios("/api/opportunities/statuses").then((resp) => {
        this.statuses = resp.data;
      });
    },
    getStatus(status) {
      if (status === 1) {
        return "Gagné";
      } else {
        return "Perdu";
      }
    },
    transfert() {
      this.$axios.patch("/api/opportunities/" + this.$route.params.id + "/transfert", { userId: this.transfUsr }).then((resp) => {
        this.item = resp.data;
        this.$simpleToast.success("Transfert Opportunité", "L'opportunité a été transféré");
        this.modalTransf = false;
      });
    },
    setAsUser(event) {
      this.transfUsr = event;
    },
    async deleteOpp() {
      const res = await this.$confirm("Êtes-vous sur ?", { title: "Confirmation", color: "primary" });
      if (res) {
        this.$axios.delete("/api/opportunities/" + this.$route.params.id).then(() => {
          this.$simpleToast.success("Opportunité", "L'élément a bien été supprimé");
          this.$router.push({ name: "Opportunités" });
        });
      }
    },
    validateRelance() {
      this.closeOppLoading = true;
      this.$axios.post("/api/opportunities/" + this.$route.params.id + "/remind", { date: this.date }).then((resp) => {
        this.infoModal = false;
        this.$simpleToast.success("Opportunité", "La relance a bien été créée");
        this.$router.push({ name: "Opportunité", params: { id: resp.data.opp.id } });
        this.$forceUpdate();
      });
      this.closeOppLoading = false;
    },
    validate() {
      this.$refs.form.validate();
      this.closeOppLoading = true;
      console.log(this.concuWin);
      if (!this.valid) {
        this.$simpleToast.error("Opportunité", "Un des champs est vide.");
      } else {
        this.step = 100;
        this.$axios
          .post("/api/opportunities/" + this.$route.params.id + "/close", {
            comments: this.comments,
            state: this.state,
            concuWin: this.concuWin,
          })
          .then(() => {
            this.getSteps();
            this.$emit("refresh-all");
            this.dialog = false;
            this.$simpleToast.success("Etat Opportunité", "Le changement d'état a bien été validé.");
          });
      }
      this.closeOppLoading = false;
    },
    async setProgression(stepValue) {
      if (this.isGranted("OPPORTUNITIES_EDIT_UPDATESTATUS")) {
        this.step = stepValue;
        this.$axios.post("/api/opportunities/" + this.$route.params.id + "/state", { state: this.step / 25 }).then(() => {
          this.getSteps();
          this.$emit("refresh-all");
          this.$simpleToast.success("Etat Opportunité", "Le changement d'état a bien été validé.");
        });
      }
    },
    async setStatus(state) {
      if (state === false) {
        this.dialog = !this.dialog;
      }
    },
  },
  watch: {
    "$route.params.id"() {
      this.getSteps();
      this.getStatuses();
    },
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    value() {
      this.item = this.value;
      this.date = this.item.opp.deadline ? dayjs(this.item.opp.deadline).format("YYYY-MM-DD") : null;

      this.step = this.item && this.item.opp.stepId > 0 ? this.item.opp.stepId * 25 : 0;
      if (this.item.opp.opened === false) {
        this.step = 100;
      }
    },
    item() {
      this.$emit("input", this.item);
    },
  },
};
</script>

<style>
.ui.steps .step.active,
.ui.steps .step.active:after,
.ui.steps .disabled.step,
.ui.steps .step.disabled:after {
  background: #90a4ae;
  cursor: default !important;
}
.ui.steps .step.active i {
  color: #ffffff !important;
}
.ui.steps .step.active .title {
  color: white;
}
</style>
